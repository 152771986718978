<template>
    <div class="header-container">
            <div class="header">
                <div class="login-img" @click="goHome" >
                    <img :src="config.logo" />
                </div>
                <div class="login-search" v-if="isSearch"  >
                    <el-input @keyup.enter.native="search" style="border-radius:40px;" placeholder="请输入搜索内容" v-model="searchForm.search">
                        
                        <i v-if="searchForm.search  && searchEvent"
                            class="el-icon-close el-input__icon"
                            slot="suffix"
                            @click="closeSearch">
                        </i>

                        <i slot="prefix" class="el-input__icon el-icon-search"></i>
                    </el-input>
                </div>
                <div class="header-right">
                    <div class="header-orther-desc" @click="goAbout">关于我们</div>
                    <div class="header-orther-desc popper-container"  @click="showDown()">
                        APP下载
                        <div class="popper" v-if="isShowDown">
                            <div class='android_download_url popper-item' >
                                <span>安卓下载</span>
                                <VueQr :size="150" :text="config.android_download_url" ></VueQr >
                            </div>

                            <div class='ios_download_url popper-item'>
                                <span>IOS下载</span>
                                <VueQr :size="150" :text="config.ios_download_url" ></VueQr >
                            </div>
                        </div>  

                    </div>
                    
                </div>
            </div>
        </div>
</template>


<script>

import VueQr from 'vue-qr';

export default {
    name: 'Header',
    components: {
        VueQr
    },
    props: {
        config: {
            type: [String, Object],
            require: true
        },
        isSearch:{
            type: [Boolean],
            default:true
        }
    },
    async created() {
		
      
    },
    watch: {
     

        $route: {
            immediate: true,
            handler: function (val) {
              
                if (val.path == "/search") {
                    this.searchForm.search = val.query.keyword;
                } else {
                    this.searchForm.search = "";
                }
            },
        },
    },
    methods: {
        closeSearch(){
            this.searchForm.search = ''
        },
        search(){
            this.searchEvent = true
            this.$router.push({'path' :'/search' ,'query' :{
                keyword:this.searchForm.search
            }});
            this.$emit('handle-search');
        },
        showDown(){
            if( this.isShowDown ){
                this.isShowDown =  false;
            } else {
                this.isShowDown =  true;
            }
        },

        goHome(){
            this.$router.replace('/');
        },

        goAbout(){
            this.$router.replace('/about');
        }

    },

    data() {
        return {
          searchEvent:false,
          searchForm:{
            search:''
          },
          isShowDown:false,
        }
    },
}
</script>


<style lang="scss" scoped>
    
    .login-img img{height:60px;}
    .header-container{
      
        display: flex;
        justify-content: center;
        width: 100vw;
        height: 72px;
        position: fixed;
        left: 0;
        top: 0;
        z-index: 10;
    }

    .header {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width:1700px;
        height: 72px;
        padding: 0 16px 0 24px;
        z-index: 10;
    }
    .login-search{
       
        width: 46vw;
        height: 40px;
        position: fixed;
        left: 50%;
        transform: translate(-50%);
        
        .el-input__inner{
            background-color: #f5f5f5 !important;
        }
    }
    
    .header-right{
        display:flex;
    }
    .header-orther-desc{
        cursor: default;
        color: rgba(51,51,51,.8);
        font-size: 16px;
        padding: 0 16px;
    }

    .popper-container{
        position:relative;
    }
    .popper{
        position: absolute;
        right: 0;
        background: #fff;
        width: 309px;
        display: flex;
        top: 40px;
        border: solid 1px #e5e3e3;
        padding-top: 10px;
    }
    .popper-item{
        text-align: center;
    }
</style>